import React from 'react';
import Layout from '../components/layout/layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Seo from '../components/seo/seo';

export interface IPrivacyPolicyProps {}

const PrivacyPolicy: React.FC<IPrivacyPolicyProps> = () => {
  return (
    <Layout>
      <Seo title="404 Strony nie znaleziono - UbezpieczamyMieszkanie.pl" />
      <div className="min-h-[370px] bg-blueLight">
        <div className="container mx-auto max-w-5xl px-4 py-6 lg:py-20">
          <h1 className="text-center text-2xl font-semibold lg:text-4xl">
            Błąd 404
          </h1>
          <div className="mt-10 flex flex-col items-center">
            Strony nie znaleziono
            <div className="">
              <Link to="/" className="mt-5 block text-primary">
                Wróć na stronę główną
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
